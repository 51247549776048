import type React from 'react';
import { twMerge } from 'tailwindMerge.config';

interface LoadingImageProps {
  width?: number;
  height?: number;
  className?: string;
}

interface LoadingTextProps {
  className?: string;
  text?: string;
}

const DEFAULT_LOADING_IMAGE_SIZE = 100;

export const LoadingImage: React.FC<LoadingImageProps> = ({
  width = DEFAULT_LOADING_IMAGE_SIZE,
  height = DEFAULT_LOADING_IMAGE_SIZE,
  className = '',
}) => {
  return (
    <img
      src="/original-icons/loading.svg"
      alt="Loading"
      width={width}
      height={height}
      className={className}
    />
  );
};

export const LoadingText: React.FC<LoadingTextProps> = ({
  className = '',
  text = 'Loading...',
}) => {
  return (
    <span className={twMerge('text-12 text-gray-10', className)}>{text}</span>
  );
};
